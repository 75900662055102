import request from "@/utils/request.js";

export const getFigure = (params) => request.post("/api/video/get_backgrounds", params);
export const getVideo = (params) => request.post("/api/video/get_video_list", params);
// export const craftVideo = (params) => request.post('/api/video/submit_video', params)
export const craftVideo = (params) => request.post("/api/lackvideo/submit_video", params);
export const del_video = (params) => request.post("/api/video/del_video", params);
export const get_image_list = (params) => request.post("/api/video/get_image_list", params);
export const sut_image_copy = (params) => request.post("/api/peoplecopy/sut_image_copy", params);
export const select_image_copy = (params) => request.post("/api/peoplecopy/select_image_copy", params);
export const image_copy_list = (params) => request.post("/api/peoplecopy/image_copy_list", params);
export const set_image_copy = (params) => request.post("/api/peoplecopy/set_image_copy", params);
export const add_div_video = (params) => request.post("/api/video/add_div_video", params);
